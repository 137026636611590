<template>
  <div class="whoareWeSection container text-center my-5 ">
    <h1 class="sectionTitle text-orange fw-sm-normal fw-bold text-uppercase">À propos</h1>
    <div class="textContent text-center fw-bold fs-6 py-3">
      <p>UBIBAT est une entreprise spécialisée dans le domaine du calcul de structures, du
      diagnostic et de la reconnaissance des structures existantes et du suivi des chantiers.<br>
      Fondée par des ingénieurs spécialisés dans le domaine des calculs de structures, UBIBAT
      intervient sur plusieurs projets couvrant divers types d’ouvrages : bureaux, logements,
      bâtiments industriels universités, hôpitaux, usines, ouvrages d’art et génie civil…  <br>
      UBIBAT réalise les études préliminaires de faisabilité, études d’avant-projet, et les études
      d’exécution sur les projets neufs. <br>
      Dotée de matériels d’inspections et d’auscultation, la société UBIBAT réalise aussi les
      missions de diagnostics structurels avec les sondages de reconnaissances, les
      inspections périodiques, les préconisations sur les travaux de remise en état nécessaires
      avec une estimation financière des travaux . <br>
      Muni d’une expérience de plus que 4 ans sur le terrain, UBIBAT accompagne ses clients en
      phase d’exécution et assure les missions liées à la conduite des travaux.
      </p>
    </div>
      
      
  </div>
</template>
