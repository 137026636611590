<template>
  <!-- prettier-ignore -->
  <div class="team container pb-5">
      <h1 class="sectionTitle fw-bold mb-5 text-uppercase">Notre équipe</h1>
      <div class="d-flex justify-content-around align-items-center flex-wrap">
        <TeamCard :name="ahmedInfos.name" :email="ahmedInfos.email"  :profession="ahmedInfos.profession" :phoneNumber="ahmedInfos.phoneNumber" :linkedIn="ahmedInfos.linkedIn" :imgPath="ahmedInfos.imgPath" />
        <TeamCard :name="azmiInfos.name" :email="azmiInfos.email" :profession="azmiInfos.profession" :phoneNumber="azmiInfos.phoneNumber" :linkedIn="azmiInfos.linkedIn" :imgPath="azmiInfos.imgPath" />
      </div> 

  </div>
</template>

<script>
import TeamCard from "@/components/TeamCard.vue";
export default {
  components: { TeamCard },
  data() {
    return {
      ahmedInfos: {
        name: "Ahmed kchaou",
        profession :"Ingénieur chargé d'affaires",
        email: "contact@ubibat.fr",
        phoneNumber: "+33 6 64 27 86 57",
        linkedIn: "https://www.linkedin.com/in/ahmed-kchaou-9829a0110",
        imgPath:"ahmed"
      },
      azmiInfos: {
        name: "Azmi Ammar",
        profession :"Responsable pôle diagnostic",
        email: "contact@ubibat.fr",
        phoneNumber: "+33 6 41 27 38 32",
        linkedIn: "https://www.linkedin.com/in/azmi-ammar-2a93bb169/",
        imgPath:"azmi"
      },
    };
  },
};
</script>

<style lang="scss">
.team {
  .member {
    width: 300px;
    height: 400px;
    text-align: center;
    border-radius: 5px;
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
    img{
      width: 300px;
      height: 320px;
    }
    .icons {
      position: absolute;
      left: 0;
      bottom: 0;
      right: 0;
      height: 40px;
      // opacity: 0;
      opacity: 1;
      transition: ease-in-out 0.3s;
      text-align: center;
      background: rgba(255, 255, 255, 0.85);
      a {
        font-size: 18px;
        transition: color 0.3s ease-in-out;
        color: var(--main-black-color);
        &:hover{
          color: var(--main-red-color) !important;
        }
      }
    }
    &:hover .icons{
      // opacity: 1;
    }

  }
}
</style>
